import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`With a static entity id (to be used in a template sensor)`}</h2>
    <pre><code parentName="pre" {...{}}>{`{% set changed_by_id = states.switch.w01.context.user_id  %}
{% set changed_by = (states.person | selectattr('attributes.user_id','==', changed_by_id))| list | first or "system" %}
Changed by: {{ "System" if changed_by == "system" else state_attr(changed_by.entity_id, "friendly_name") }}
`}</code></pre>
    <h2>{`With using the entity that triggered an automation`}</h2>
    <pre><code parentName="pre" {...{}}>{`{% set changed_by_id = trigger.to_state.context.user_id  %}
{% set changed_by = (states.person | selectattr('attributes.user_id','==', changed_by_id))| list | first or "system" %}
Changed by: {{ "System" if changed_by == "system" else state_attr(changed_by.entity_id, "friendly_name") }}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      